export const requiredNumber = (
  value: number | null | undefined,
  message?: string
): string | null => {
  const errorMessage = message ? message : "Field is required";

  if (!value || isNaN(value)) {
    return errorMessage;
  }

  return null;
};

export const requiredString = (
  value: string | null | undefined,
  message?: string
): string | null => {
  const errorMessage = message ? message : "Field is required";

  if (!value) {
    return errorMessage;
  }

  return null;
};
