import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateUser } from 'api/organizations/users/users';
import i18n from 'i18n';
import { UpdateUserPayload } from 'types/user/User';
import { endNotification, generateRandomString, startNotification } from 'utils/common';

export const useUpdateUser = () => {
    const queryClient = useQueryClient();
  const randomId = generateRandomString(20);

  
  return useMutation((params: { id: number; data: UpdateUserPayload }) => updateUser(params.id, params.data), {
    onMutate: () => {
      startNotification(randomId);
    },
    onSuccess: () => {
      endNotification(
        randomId,
        i18n.t("Users:notifications.updateSuccess"),
        true
      );
      queryClient.invalidateQueries(["users"]);
    },
    onError: () => {
      endNotification(
        randomId,
        i18n.t("Users:notifications.createError"),
        false
      );
    },
  });
};