import { useQuery } from "@tanstack/react-query";
import { getOrganization } from "../../api/organizations/organizations";

export const useOrganization = (organizationId: number) => {
  return useQuery(
    ["organizationDetails", organizationId],
    () => getOrganization(organizationId),
    {
      enabled: !!organizationId,
    }
  );
};