/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

// Components
import {
  Box,
  Button,
  Group,
  Space,
  Textarea,
  TextInput,
  Text,
  RadioGroup,
  Radio,
  Checkbox,
  Modal,
  NumberInput, Select
} from '@mantine/core';
import { useForm } from "@mantine/form";
import { RichTextEditor } from "@mantine/rte";
import { ImageUpload } from "components/common";

// Hooks
import { useTemplate } from "hooks/templates/useTemplate";

// Types
import { ApiError } from "types/api";
import { ErrorCode } from "enums/api";
import { Color } from "enums/common";
import { Exercise } from "types/exercises/exercises";

// Utils
import { requiredString } from "utils/form/validatorsUtils";
import { removeAllHtmlTags } from "utils/common/htmlUtils";
import { RichTextContent } from "components/RichText";
import { useExerciseContentPreview } from "hooks/exercises/exercisepages/useExerciseContentPreview";
import { ContentPreview } from "types/exercises/exercisepages";
import { useGetVoiceModels } from '../../hooks/exercises/useGetVoiceModels';
import { VoiceModel } from '../../types/voice-models/VoiceModel';

// Style
const DescriptionSticky = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export interface CreateModalProps {
  exercise: Exercise;
  mutation: any;
}

type VoiceBotGender = "Male" | "Female"

export const EditChatBot3Form: React.FC<CreateModalProps> = ({
  exercise,
  mutation,
}) => {
  // State
  const [slideText, setSlideText] = useState(exercise.introSlideText);
  const [coverImage, setCoverImage] = useState<{
    source: string | undefined;
    isUpdate: boolean;
  }>();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [_contentPreview, setContentPreview] = useState<ContentPreview>(
    {} as ContentPreview
  );
  const [selectedVoiceBotGender,setSelectedVoiceBotGender] = useState<VoiceBotGender | null>(null);
  const [selectedVoiceBot,setSelectedVoiceBot] = useState<VoiceModel | null>(null);
  const genderOptions: VoiceBotGender[] = ["Male","Female"]

  const {data: voiceModelsData} = useGetVoiceModels();
  const voiceBotModels = selectedVoiceBotGender === 'Male' ? voiceModelsData?.maleVoices : voiceModelsData?.femaleVoices

  // Hooks
  const { t } = useTranslation();
  const navigate = useNavigate();
  const minimumPointsValue = 0;

  const form = useForm({
    initialValues: {
      id: exercise.id,
      title: exercise.title || "",
      subtitle: exercise.subtitle,
      coverImage: "",
      isPublic: 0,
      points: exercise.points ?? 0,
      skills: "",
      level: 0,
      imageUrl: exercise.imageUrl,
      imageId: exercise.imageId,
      duration: 0,
      languageId: exercise.language ? exercise.language.id.toString() : "0",
      organizationId: null,
      description: exercise.description,
      introSlideText: exercise.introSlideText,
      prompt: exercise.prompt,
      limitOfMessages: exercise.limitOfMessages,
      analysesPrompt: exercise.analysesPrompt,
      templateId: 0,
      enabledScriptHiding: "",
      endSummaryPrompt: exercise.endSummaryPrompt,
      modelEndpoint: exercise.modelEndpoint,
      modelKey: exercise.modelKey,
      voiceModelId: selectedVoiceBot?.id,
      isDescriptionSticky: exercise.isDescriptionSticky,
    },
    validate: {
      title: (value: string) => requiredString(value),
      modelEndpoint: (value: string, values) =>
        values.modelKey ? requiredString(value) : null,
      modelKey: (value: string, values) =>
        values.modelEndpoint ? requiredString(value) : null,
      points: (value: number) =>
        value < 0 ? t("Exercises:errors.numberGreaterThanZero") : null,
    },
  });

  // Services
  const { data } = useTemplate(exercise.templateId!);

  // Services
  const { mutate } = useExerciseContentPreview();

  // Handlers
  const handleSubmit = () => {
    // Calculate inputs
    const description =
      removeAllHtmlTags(form.values?.description).length > 0
        ? form.values?.description
        : "";
    mutation.mutate(
      {
        ...form.values,
        isPublic: "true",
        points: Number(form.values.points),
        duration: Number(form.values.duration),
        languageId: Number(form.values.languageId),
        introSlideText: slideText,
        templateId: exercise.templateId,
        enabledScriptHiding: "false",
        coverImage: coverImage?.isUpdate
          ? coverImage?.source
          : exercise.imageId,
        description,
      },
      {
        onSuccess: () => {
          navigate(`/exercises/${exercise.id}`);
        },
        onError: (error: AxiosError<ApiError>) => {
          if (
            error.response?.data.errorCode === ErrorCode.NAME_ALREADY_EXISTS
          ) {
            form.setFieldError("title", t("Exercises:errors.nameExists"));
          }
        },
      }
    );
  };

  // Effects
  useEffect(() => {
    setCoverImage({ source: exercise.imageUrl, isUpdate: false });
  }, [exercise.imageUrl]);

  return (
    <Box sx={{ maxWidth: 800 }} mx="auto">
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <TextInput
          required
          label={t("Exercises:createExercise.title")}
          styles={() => ({
            input: {
              fontSize: 14,
              color: Color.DARK,
              fontFamily: "Montserrat",
              borderColor: Color.DARK,
              borderWidth: 1,
              backgroundColor: Color.WHITE,
              "&:focus": {
                borderColor: Color.DARK,
                borderWidth: 1,
              },
            },
          })}
          {...form.getInputProps("title")}
        />
        <Space h="md" />
        <TextInput
          label={t("Exercises:createExercise.subtitle")}
          styles={() => ({
            input: {
              fontSize: 14,
              color: Color.DARK,
              fontFamily: "Montserrat",
              borderColor: Color.DARK,
              borderWidth: 1,
              backgroundColor: Color.WHITE,
              "&:focus": {
                borderColor: Color.DARK,
                borderWidth: 1,
              },
            },
          })}
          {...form.getInputProps("subtitle")}
        />
        <Space h="md" />

        <Text style={{ fontSize: 14, color: Color.DARK }}>
          {t("Exercises:createExercise.description")}
        </Text>
        <RichTextEditor
          style={{
            borderColor: Color.DARK,
            fontFamily: "Montserrat",
            minHeight: 180,
          }}
          {...form.getInputProps("description")}
        />

        <Space h="md" />

        <DescriptionSticky>
          <Text
            style={{
              fontSize: 14,
              color: Color.DARK,
              paddingBottom: 5,
              whiteSpace: "nowrap",
            }}
          >
            {t("Exercises:createExercise.makeDescriptionSticky")}
          </Text>
          <Checkbox
            defaultChecked={exercise.isDescriptionSticky}
            style={{ borderColor: "#000" }}
            {...form.getInputProps("isDescriptionSticky")}
          />
        </DescriptionSticky>

        <Space h="md" />

        <NumberInput
          required
          label={t("Exercises:createExercise.points")}
          styles={() => ({
            input: {
              fontSize: 14,
              color: Color.DARK,
              fontFamily: "Montserrat",
              borderColor: Color.DARK,
              borderWidth: 1,
              backgroundColor: Color.WHITE,
              "&:focus": {
                borderColor: Color.DARK,
                borderWidth: 1,
              },
            },
          })}
          {...form.getInputProps("points")}
          min={minimumPointsValue}
        />

        <Space h="md" />

        <TextInput
          label={t("Exercises:createExercise.template")}
          placeholder={t("Exercises:createExercise.template")}
          required
          readOnly
          value={data?.title}
          onChange={() => data?.id}
          styles={() => ({
            input: {
              fontSize: 14,
              color: Color.DARK,
              fontFamily: "Montserrat",
              borderColor: Color.DARK,
              borderWidth: 1,
              backgroundColor: Color.WHITE,
              "&:focus": {
                borderColor: Color.DARK,
                borderWidth: 1,
              },
            },
          })}
        />

        <Space h="xl" />

        <RadioGroup
          label={t("Exercises:createExercise.language")}
          orientation="vertical"
          required
          styles={{
            label: { fontSize: 14, color: Color.DARK },
            radio: {
              fontSize: 14,
              color: Color.DARK,
            },
            inner: { fontSize: 14, color: Color.DARK },
            icon: { fontSize: 14, color: Color.DARK },
            radioWrapper: { fontSize: 14, color: Color.DARK },
          }}
          {...form.getInputProps("languageId")}
        >
          <Radio value="0" label={t("Exercises:createExercise.english")} />
          <Radio value="1" label={t("Exercises:createExercise.norway")} />
          <Radio value="2" label={t("Exercises:createExercise.swedish")} />
        </RadioGroup>

        <Space h="xl" />

        <ImageUpload
          avatarPreviewUrl={coverImage?.source}
          setAvatarIdentifier={(e) => {
            setCoverImage({ source: e, isUpdate: true });
          }}
        />

        <Space h="md" />

        <Text
          style={{
            color: Color.DARK,
            fontSize: 14,
            fontWeight: 400,
            paddingBottom: 4,
          }}
        >
          {t("Exercises:createExercisePage.slideText")}
        </Text>
        <RichTextEditor
          style={{ borderColor: "#000" }}
          value={slideText}
          onChange={setSlideText}
        />
        <Space h="md" />

        <div style={{ position: "relative", padding: ".5rem 0" }}>
          <Textarea
            autosize
            required
            label="Prompt"
            styles={() => ({
              input: {
                fontSize: 14,
                color: Color.DARK,
                fontFamily: "Montserrat",
                borderColor: Color.DARK,
                borderWidth: 1,
                backgroundColor: Color.WHITE,
                "&:focus": {
                  borderColor: Color.DARK,
                  borderWidth: 1,
                },
              },
            })}
            minRows={7}
            mb={3}
            {...form.getInputProps("prompt")}
          />

          <Button
            onClick={() => {
              setModalOpen(true);
              mutate(
                {
                  description: form.values.description,
                  prompt: form.values.prompt,
                },
                {
                  onSuccess: (data) => {
                    setContentPreview(data);
                  },
                }
              );
            }}
            title="Preview"
            size="sm"
            styles={(theme) => ({
              root: {
                position: "absolute",
                bottom: 0,
                right: 0,
                fontSize: 16,
                padding: ".2rem",
                margin: 0,
                fontFamily: "Montserrat",
                backgroundColor: theme.fn.lighten(Color.DARK, 0.1),
                fontWeight: 500,
                height: "max-content",

                "&:hover": {
                  backgroundColor: theme.fn.lighten(Color.DARK, 0.1),
                },
              },
            })}
          >
            Preview
          </Button>
        </div>

        <Space h="md" />
        <TextInput
          required
          label="Number of messages"
          styles={() => ({
            input: {
              fontSize: 14,
              color: Color.DARK,
              fontFamily: "Montserrat",
              borderColor: Color.DARK,
              borderWidth: 1,
              backgroundColor: Color.WHITE,
              "&:focus": {
                borderColor: Color.DARK,
                borderWidth: 1,
              },
            },
          })}
          {...form.getInputProps("limitOfMessages")}
        />

        <Space h="md" />

        <Select
          label="Select voicebot gender"
          value={selectedVoiceBotGender}
          onChange={(value) => {
            setSelectedVoiceBotGender(value as VoiceBotGender);
            setSelectedVoiceBot(null);
          }}
          data={genderOptions}
        />

        <Space h="md" />

        {selectedVoiceBotGender && (
          <Select
            label="Available voice bots"
            value={form.values.voiceModelId?.toString() ?? ""}
            onChange={(value) => {
              const selectedVoiceBot = voiceBotModels?.find(
                (model) => model.id.toString() === value
              );
              form.setFieldValue("voiceModelId", selectedVoiceBot?.id || undefined);
            }}
            data={
              voiceBotModels?.map((model) => ({
                value: model.id.toString(),
                label: model.name,
              })) || []
            }
          />
        )}

        <Space h="md" />

        <Textarea
          autosize
          required
          label="Analyses prompt"
          styles={() => ({
            input: {
              fontSize: 14,
              color: Color.DARK,
              fontFamily: "Montserrat",
              borderColor: Color.DARK,
              borderWidth: 1,
              backgroundColor: Color.WHITE,
              "&:focus": {
                borderColor: Color.DARK,
                borderWidth: 1,
              },
            },
          })}
          minRows={7}
          mb={3}
          {...form.getInputProps("analysesPrompt")}
        />

        <Space h="md" />

        <Textarea
          autosize
          required
          label="End Summary Prompt"
          styles={() => ({
            input: {
              fontSize: 14,
              color: Color.DARK,
              fontFamily: "Montserrat",
              borderColor: Color.DARK,
              borderWidth: 1,
              backgroundColor: Color.WHITE,
              "&:focus": {
                borderColor: Color.DARK,
                borderWidth: 1,
              },
            },
          })}
          minRows={7}
          mb={3}
          {...form.getInputProps("endSummaryPrompt")}
        />

        <Space h="md" />

        <TextInput
          label="Model endpoint"
          styles={() => ({
            input: {
              fontSize: 14,
              color: Color.DARK,
              fontFamily: "Montserrat",
              borderColor: Color.DARK,
              borderWidth: 1,
              backgroundColor: Color.WHITE,
              "&:focus": {
                borderColor: Color.DARK,
                borderWidth: 1,
              },
            },
          })}
          {...form.getInputProps("modelEndpoint")}
        />

        <Space h="md" />

        <TextInput
          label="Model key"
          styles={() => ({
            input: {
              fontSize: 14,
              color: Color.DARK,
              fontFamily: "Montserrat",
              borderColor: Color.DARK,
              borderWidth: 1,
              backgroundColor: Color.WHITE,
              "&:focus": {
                borderColor: Color.DARK,
                borderWidth: 1,
              },
            },
          })}
          {...form.getInputProps("modelKey")}
        />

        <Group position="right" mt="md">
          <Button
            data-testid="submit-button"
            type="submit"
            size="sm"
            styles={(theme) => ({
              root: {
                fontSize: 16,
                fontFamily: "Montserrat",
                backgroundColor: Color.DARK,
                fontWeight: 600,

                "&:hover": {
                  backgroundColor: theme.fn.lighten(Color.DARK, 0.1),
                },
              },
            })}
          >
            {t("Common:actions.submit")}
          </Button>
        </Group>
      </form>

      <Modal
        opened={modalOpen}
        onClose={() => setModalOpen(false)}
        centered
        size={700}
        overflow="inside"
        title={
          <div style={{ fontSize: 22, fontWeight: "bold" }}>Preview mode</div>
        }
      >
        <>
          {form.values.prompt.length > 0 && (
            <>
              {removeAllHtmlTags(form.values?.description).length > 0 ? (
                <div style={{ whiteSpace: "pre-line" }}>
                  <h3>Description:</h3>

                  <RichTextContent htmlContent={_contentPreview.description} />
                </div>
              ) : (
                <h3>No description text to show!</h3>
              )}

              <div style={{ whiteSpace: "pre-line" }}>
                <h3>Prompt:</h3>
                {_contentPreview.prompt}
              </div>
            </>
          )}

          {!form.values.prompt.length && <p>Please fill the prompt first</p>}
        </>
      </Modal>
    </Box>
  );
};
