import { Exercises } from "enums/exercises/urls";
import { VoiceModelResponse } from '../../types/voice-models/VoiceModelResponse';
import { createAxiosInstance } from '../config';

const axios = createAxiosInstance();

export const getExercises = async (): Promise<VoiceModelResponse> => {
  const { data } = await axios.get(`/${Exercises.EXERCISES}/voice-models`);
  return data;
};
