import { useTranslation } from "react-i18next";

// Components
import {
  Modal,
  TextInput,
  Group,
  Button,
  Select,
  Space,
  Box,
  MultiSelect,
} from "@mantine/core";
import { useForm } from "@mantine/form";

// types

import { Color } from "enums/common";

// Utils
import { requiredString } from "utils/form/validatorsUtils";
import { User } from "types/user/User";
import { Dispatch, SetStateAction } from "react";
import { useOrganization } from "hooks/organizations/useOrganization";
import { useUpdateUser } from "hooks/organizations/users/updateUser";

export interface CreateUserModalProps {
  user: User;
  setUser: Dispatch<SetStateAction<User | undefined>>;
}

export const UserFormModal: React.FC<CreateUserModalProps> = ({
  user,
  setUser,
}) => {
  // Hooks
  const { t } = useTranslation();

  const {
    fullName,
    nickName,
    mobile,
    gender,
    expertLevel,
    departments,
    roles,
  } = user || {};

  // State
  const form = useForm({
    initialValues: {
      fullName,
      nickName,
      mobile,
      genderId: gender.id.toString(),
      expertLevelId: expertLevel?.id.toString() ?? undefined,
      organizationId: user.organization?.id,
      departmentIds: departments?.map(({ departmentId }) =>
        departmentId.toString()
      ),
      roleIds: roles?.map(({ id }) => id.toString()),
    },
    validate: {
      expertLevelId: (value) =>
        requiredString(value, t("Exercises:notifications.requiredField")),
      fullName: (value) =>
        requiredString(value, t("Exercises:notifications.requiredField")),
      nickName: (value) =>
        requiredString(value, t("Exercises:notifications.requiredField")),
      mobile: (value) =>
        requiredString(value, t("Exercises:notifications.requiredField")),
      roleIds: (value) =>
        value?.length ? null : t("Exercises:notifications.requiredField"),
    },
  });

  const { data } = useOrganization(user?.organization?.id || 0);

  const { mutate: UpdateUser } = useUpdateUser();

  const handleSubmit = () => {
    UpdateUser(
      {
        id: user.id,
        data: {
          ...form.values,
          departmentIds: form.values.departmentIds?.map((v) => +v),
          roleIds: form.values.roleIds?.map((v) => +v),
        },
      },
      {
        onSuccess: () => {
          setUser(undefined);
        },
      }
    );
  };

  // Select options data
  const departmentOptions = data?.departments?.map(({ id, name }) => ({
    value: id.toString(),
    label: name.toString(),
  }));

  const genderOptions = [
    {
      value: "1",
      label: t("Common:male"),
    },
    {
      value: "2",
      label: t("Common:female"),
    },
  ];

  const languageOptions = [
    {
      value: "1",
      label: t("Common:languages.english"),
    },
    {
      value: "2",
      label: t("Common:languages.norwegian"),
    },
  ];

  const expertLevelOptions = [
    {
      value: "1",
      label: t("Common:expertLevel.beginner"),
    },
    {
      value: "2",
      label: t("Common:expertLevel.intermediate"),
    },
    {
      value: "3",
      label: t("Common:expertLevel.experienced"),
    },
  ];

  const roleOptions = [
    {
      value: "3",
      label: t("Users:roles.createUser.memberRoleLabel"),
    },
    {
      value: "4",
      label: t("Users:roles.createUser.managerRoleLabel"),
    },
    {
      value: "2",
      label: t("Users:roles.createUser.administratorRoleLabel"),
    },
  ];

  return (
    <Modal
      centered
      data-testid="create-modal"
      size="lg"
      title={"Update user"}
      opened={!!user}
      onClose={() => setUser(undefined)}
    >
      <Box>
        <form onSubmit={form.onSubmit(handleSubmit)} noValidate>
          <TextInput
            required
            label={t("Users:form.fullName.label")}
            placeholder={t("Users:form.fullName.placeholder")}
            {...form.getInputProps("fullName")}
          />
          <Space h="md" />
          <TextInput
            required
            label={t("Users:form.nickName.label")}
            placeholder={t("Users:form.nickName.placeholder")}
            {...form.getInputProps("nickName")}
          />
          <Space h="md" />
          <TextInput
            required
            label={t("Users:form.mobile.label")}
            placeholder={t("Users:form.mobile.placeholder")}
            {...form.getInputProps("mobile")}
          />
          <Space h="md" />
          <Select
            label={t("Users:form.gender.label")}
            placeholder={t("Users:form.gender.placeholder")}
            data={genderOptions}
            {...form.getInputProps("genderId")}
            styles={{
              input: {
                fontFamily: "Montserrat",
                fontWeight: 600,
                color: Color.DARK,
                borderColor: Color.DARK,
                borderWidth: 1,
              },
            }}
          />
          <Space h="md" />
          {departmentOptions && (
            <MultiSelect
              label={t("Users:form.department.label")}
              placeholder={t("Users:form.department.placeholder")}
              data={departmentOptions}
              {...form.getInputProps("departmentIds")}
              styles={{
                input: {
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                  color: Color.DARK,
                  borderColor: Color.DARK,
                  borderWidth: 1,
                },
              }}
            />
          )}
          <Space h="md" />
          <Select
            label={t("Users:form.language.label")}
            placeholder={t("Users:form.language.placeholder")}
            data={languageOptions}
            {...form.getInputProps("language")}
            styles={{
              input: {
                fontFamily: "Montserrat",
                fontWeight: 600,
                color: Color.DARK,
                borderColor: Color.DARK,
                borderWidth: 1,
              },
            }}
          />

          <Space h="md" />
          <Select
            required
            label={t("Users:form.expertLevel.label")}
            placeholder={t("Users:form.expertLevel.placeholder")}
            data={expertLevelOptions}
            {...form.getInputProps("expertLevelId")}
            styles={{
              input: {
                fontFamily: "Montserrat",
                fontWeight: 600,
                color: Color.DARK,
                borderColor: Color.DARK,
                borderWidth: 1,
              },
            }}
          />
          <Space h="md" />

          <MultiSelect
            label={t("Users:roles.createUser.selectRoleTitle")}
            placeholder={t("Users:roles.createUser.selectRoleTitle")}
            data={roleOptions}
            {...form.getInputProps("roleIds")}
            required
            styles={{
              input: {
                fontFamily: "Montserrat",
                fontWeight: 600,
                color: Color.DARK,
                borderColor: Color.DARK,
                borderWidth: 1,
              },
            }}
          />
          <Space h="md" />
          {/* <ImageUpload setAvatarIdentifier={setAvatarUrl} /> */}
          <Group position="right" mt="md">
            <Button data-testid="submit-button" type="submit">
              {t("Common:actions.submit")}
            </Button>
          </Group>
        </form>
      </Box>
    </Modal>
  );
};
