import { createAxiosInstance } from "api/config";
import { USER } from "../../../enums/users/urls";
import { CreateUserPayload, UpdateUserPayload } from "../../../types/user/User";

const axios = createAxiosInstance();

export const createUser = async (
  payload: CreateUserPayload
): Promise<number> => {
  payload.departmentIds = payload.departmentIds.map(Number);
  payload.roleIds = payload.roleIds.map(Number);
  payload.genderId = Number(payload.genderId);

  const { data } = await axios.post(`/${USER.CREATE_USER}`, payload);

  return data;
};

export const deleteUser = async (userId: number) => {
  const response = await axios.delete(`/${USER.USERS}/${userId}`);
  return response.data;
};

export const updateUser = async (id: number, payload: UpdateUserPayload) => {
  const response = await axios.put(`/${USER.USERS}/${id}`, payload);
  return response.data;
};

